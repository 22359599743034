<template>
    <div class="wrap mr-1" :class="editing ? classSize : ''">
        <p class="txt mb-1 pl-2 py-1 border border-white" v-show="!editing">{{ value }}</p>
        <select :id="id" class="py-1 px-2 mb-1 border rounded-md bg-gray-50 border-gray-300 w-full" :name="id" size="1"
            v-model="internalValue" v-show="editing" @change="onChange" @keyup.enter="$emit('keyup-enter')">
            <option value="" disabled selected>{{ placeholder }}</option>
            <option v-for="(option, index) in sortedOptions" :key="index" :value="option.value">
                {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        indexID: {
            required: true
        },
        classSize: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        editing: {
            required: true
        },
        placeholder: {
            required: false
        },
        options: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            internalValue: this.indexID
        };
    },
    computed: {
        sortedOptions() {
            const entries = Object.entries(this.options);
            entries.sort((a, b) => {
                const aText = a[1]?.toString() || '';
                const bText = b[1]?.toString() || '';
                return aText.localeCompare(bText);
            });
            return entries.map(([value, text]) => ({ value, text }));
        }
    },
    watch: {
        indexID(newVal) {
            if (typeof newVal === 'object') { 
                this.internalValue = newVal.target.value;
                this.$emit('input', newVal.target.value);  // Émet l'événement pour informer le parent
            } else {
                this.internalValue = newVal;
                this.$emit('input', newVal);  // Émet l'événement pour informer le parent
            }
        }
    },
};
</script>
