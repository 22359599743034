<template>
   <div id="civilite">
      <TitleCat v-if="!competences" :isAddedEntity="true" @update:editing="addItemEditing = $event">Compétences(s)</TitleCat>
      <TitleCat v-else @update:editing="addItemEditing = $event">Compétences(s)</TitleCat>

      <!-- Ajout d'une nouvelle competences -->
      <div v-if="addItemEditing" class="add flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50 mb-2">

         <TextareaField v-if="addItemEditing" :value="newCompetences" id="Competences" classSize="w-5/6" :editing="true"
            placeholder="Competences" @input="newCompetences = $event" />

         <ActionAddItem field="competences" :editing="addItemEditing" @update:editing="addItemEditing = $event"
            @is-error="isError = false" @save-add="saveAdd()">
         </ActionAddItem>
      </div>


      <div class="" :key="keyReload">
         <!-- Competences -->
         <div v-if="competences" class="flex wrap-items items-center">
            <TextareaField :value="competences" id="competences" classSize="w-5/6" :editing="editingFields.competences"
               @input="competences = $event" />

            <ActionFields :editing="editingFields.competences" field="competences" :deletable="true"
               @update:editing="editingFields.competences = $event" @save-delete="saveDelete(index)"
               @save-change="saveChange"></ActionFields>
         </div>
      </div>
   </div>
</template>

<script>
import TitleCat from "@/components/profil/global/TitleCat.vue";
// import Label from "@/components/profil/global/Label.vue";
import TextareaField from "@/components/profil/global/TextareaField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import UserService from "@/services/user.service";
export default {
   name: "Competences",
   components: {
      TitleCat,
      // Label,   
      TextareaField,
      ActionFields,
      ActionAddItem,
   },
   props: {
      competencesInfo: { required: true },
   },
   data() {
      return {
         id: this.$route.params.id,
         addItemEditing: false,
         competences: null,
         newCompetences: null,
         keyReload: 0,
         editingFields: {
            competences: false,
         },
      };
   },
   watch: {
      competencesInfo(competencesInfo) {
         if (competencesInfo[0]) {
            this.competences = competencesInfo[0].competence;
         }
      },
   },
   methods: {
      saveChange(index) {
         this.editingFields[index] = false;
         this.updateIndividu(index)
      },
      saveDelete(index) {
         this.editingFields[index] = false;
         this.deleteCompetence(index);
      },
      saveAdd() {
         this.postCompetence();
      },
      updateIndividu() {
         const datas = {
            competences: this.competences 
         };

         UserService.updateItem(this.$route.params.id, datas, 'competences').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => {
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      deleteCompetence() {
         UserService.deleteItem(this.$route.params.id, 'competences').then(
            (response) => {
               console.log('response', response);
                  this.competences = null;
                  this.$emit('reload', true);
                  this.keyReload++
                  this.addItemEditing = false;
                  this.isError = false
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      postCompetence() {
         const datas = {
            competences: this.newCompetences
         };
         UserService.postItem(this.$route.params.id, datas, 'competences').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.addItemEditing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      }, 
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
