<template>
   <div id="civilite">

      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Email(s)</TitleCat>

      <!-- Ajout d'un nouveau contact mail -->
      <div class="add">
         <div v-if="addItemEditing" class="flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50 mb-2">
            <InputTextField value="" id="nom" classSize="w-2/6" :editing="true" placeholder="Email"
               @input="newEmail = $event.target.value" @keyup-enter="saveAdd('mail')" />

            <selectField :value="newtypeEmailId" :options="emailsType" indexID="" id="type_email_id" classSize="w-2/6"
               placeholder="Type Email" :editing="true" @change="newtypeEmailId = $event.target.value"
               @keyup-enter="saveAdd('mail')" />

            <CheckBoxField :value="newIsDefautEmail" id="isdefautemailNew" classSize="w-2/6" :editing="true"
               @input="newIsDefautEmail = $event" placeholder="Défaut" textIsChecked="par défaut"
               @keyup-enter="saveAdd('mail')" />

            <ActionAddItem field="email" :editing="addItemEditing" @update:editing="addItemEditing = $event"
               @is-error="isError = false" @save-add="saveAdd()">
            </ActionAddItem>
         </div>
      </div>


      <!-- Liste des mail existant -->
      <div class="update" :key="keyReload">
         <div>
            <div v-for="(emailInfo, index) in emailsInfo" :key="index" class="flex wrap-items items-center"
               :data-id="index">

               <InputTextField :value="emailInfo.email" id="nom" classSize="w-2/6" :editing="emailInfo.editing"
                  placeholder="Email" @input="emailInfo.email = $event.target.value" @keyup-enter="saveChange(index)" />

               <selectField :value="emailsType[emailInfo.typeEmailId]" :options="emailsType"
                  :indexID="emailInfo.typeEmailId" id="type_email_id" classSize="w-2/6" :editing="emailInfo.editing"
                  @input="emailInfo.typeEmailId = $event" placeholder="Type Email" @keyup-enter="saveChange(index)" />

               <CheckBoxField :value="emailInfo.isdefautemail" :id="`isdefautemail_${index}`" classSize="w-2/6"
                  :editing="emailInfo.editing" @input="emailInfo.isdefautemail = $event" placeholder="Défaut"
                  textIsChecked="(par défaut)" @keyup-enter="saveChange(index)" />

               <ActionFields :editing="emailInfo.editing" field="email" @update:editing="emailInfo.editing = $event"
                  :deletable="true" @is-error="isError = false" @save-delete="saveDelete(index)"
                  @save-change="saveChange(index)"></ActionFields>
            </div>
         </div>
      </div>
      <ErrorNotice v-if="isError" />
   </div>
</template>

<script>
import { reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import CheckBoxField from "@/components/profil/global/CheckBoxField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import UserService from "@/services/user.service";
import { mapState } from 'vuex';
export default {
   name: "Email",
   components: {
      TitleCat,
      CheckBoxField,
      InputTextField,
      selectField,
      ErrorNotice,
      ActionFields,
      ActionAddItem
   },
   props: {
      emailsInfo: { required: true },
   },
   data() {
      return {
         id: this.$route.params.id,
         oldEmailArray: null,
         emails: reactive([]), // tableau où on stock tous les mails de maniere reactive (avec index)
         emailsType: [],
         addItemEditing: false,
         newEmail: null,
         newtypeEmailId: null,
         newIsDefautEmail: null,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         isError: false
      };
   },
   computed: {
      ...mapState('dataType', ['dataType']),
   },
   mounted() {
      this.loadEmailTypes();
   },
   watch: {
      emailsInfo: {
         handler(emailsInfo) {
            if (emailsInfo) {
               this.emails = [];
               this.emails = emailsInfo.map((info) => {
                  return {
                     email: info.email,
                     typeEmailId: info.typeEmailId,
                     isdefautemail: info.isdefautemail,
                     editing: false,
                  };
               });
            }
            if (this.oldEmailArray == null) {
               this.oldEmailArray = [];
               this.emails.forEach((emailInfo, index) => {
                  this.oldEmailArray[index] = emailInfo.email;
               });
            }
         },
         deep: true,
      },
      dataType(newValue) {
         if (newValue && newValue.typeEmails) {
            this.loadEmailTypes(); // Recharge les types si dataType change après le montage
         }
      }
   },
   methods: {
      loadEmailTypes() {
         if (this.dataType && this.dataType.typeEmails) {
            this.emailsType = {}; // Réinitialise emailsType
            this.dataType.typeEmails.forEach(item => {
               this.emailsType[item.type_email_id] = item.type_email;
            });
         } 
      },
      saveChange(index) {
         this.updateEmail(index);
      },
      saveDelete(index) {
         this.emails[index].editing = false;
         this.deleteEmail(index);
      },
      saveAdd() {
         this.postEmail();
      },

      updateEmail(index) {

         const datas = {
            typeEmailId: parseInt(this.emails[index].typeEmailId),
            email: this.emails[index].email,
            defautEmail: (this.emails[index].isdefautemail == null) ? 0 : parseInt(this.emails[index].isdefautemail),
            individuId: this.$route.params.id
         };

         const modifiedEmail = this.oldEmailArray[index].replace(/\./g, '[dot]');
         const encodedEmail = encodeURIComponent(modifiedEmail);

         UserService.updateItem(encodedEmail, datas, 'emails').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.oldEmailArray = null
               this.keyReload++
               this.isError = false
               this.emails[index].editing = false;
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },

      deleteEmail(index) {
         const modifiedEmail = this.oldEmailArray[index].replace(/\./g, '[dot]');
         const encodedEmail = encodeURIComponent(modifiedEmail);

         UserService.deleteItem(encodedEmail, 'emails').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.oldEmailArray = null
               this.keyReload++
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },

      postEmail() {
         const datas = {
            typeEmailId: this.newtypeEmailId,
            email: this.newEmail,
            defautEmail: (this.newIsDefautEmail == null) ? 0 : parseInt(this.newIsDefautEmail),
            individuId: parseInt(this.$route.params.id)
         };

         const modifiedEmail = this.newEmail.replace(/\./g, '[dot]');
         const encodedEmail = encodeURIComponent(modifiedEmail);

         UserService.postItem(encodedEmail, datas, 'emails').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.oldEmailArray = null
               this.keyReload++
               this.isError = false
               this.addItemEditing = false;
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },

   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
