<template>
   <div id="civilite">
      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Adresse(s)</TitleCat>

      <!-- Ajout d'une nouvelle adresse -->
      <div class="add">
         <div v-if="addItemEditing" class="p-2 border-b border-gray-300 bg-slate-100">
            <div class="flex wrap-items items-center">
               <selectField :value="newType_adresse_id" :options="adresseTypes" indexID="" id="type_adresse_id"
                  classSize="w-2/6" placeholder="Type d'adresse" :editing="true"
                  @change="newType_adresse_id = $event.target.value" @keyup-enter="saveAdd('adresses')" />
               <InputTextField value="" id="adresse" classSize="w-2/6" :editing="true" placeholder="Adresse"
                  @input="newAdresse = $event.target.value" @keyup-enter="saveAdd('adresses')" />

               <ActionAddItem field="adresse" :editing="addItemEditing" @update:editing="addItemEditing = $event"
                  @is-error="isError = false" @save-add="saveAdd()">
               </ActionAddItem>
            </div>


            <div class="flex wrap-items items-center">
               <InputTextField value="" id="code_postal" classSize="w-2/6" :editing="true" placeholder="Code postal"
                  @input="newCode_postal = $event.target.value" @keyup-enter="saveAdd('adresses')" />

               <InputTextField value="" id="ville" classSize="w-2/6" :editing="true" placeholder="Ville"
                  @input="newVille = $event.target.value" @keyup-enter="saveAdd('adresses')" />

               <selectField :value="newPays_id" :options="paysList" indexID="" id="pays_id" classSize="w-2/6"
                  placeholder="Pays" :editing="true" @change="newPays_id = $event.target.value"
                  @keyup-enter="saveAdd('adresses')" />

            </div>

         </div>
      </div>


      <div class="wrap-list-adress" :key="keyReload">
         <!-- Telephone -->
         <div v-for="(adresseItem, index) in adresses" :key="index" class="border-b border-gray-300 mb-2 pb-2">
            <div class="flex wrap-items items-center">
               <selectField :value="adresseTypes[adresseItem.type_adresse_id]" :options="adresseTypes"
                  :indexID="adresseItem.type_adresse_id" id="type_adresse_id" classSize="w-3/6"
                  placeholder="Type d'Adresse" :editing="false" @input="adresseItem.type_adresse_id = $event"
                  @keyup-enter="saveChange(index)" />

               <ActionFields :editing="adresseItem.editing" :index="index" field="adresse" :deletable="true"
                  @is-error="isError = false" @update:editing="adresseItem.editing = $event"
                  @save-delete="saveDelete(index)" @save-change="saveChange(index)"></ActionFields>
            </div>

            <div class="flex wrap-items items-center">
               <InputTextField :value="adresseItem.adresse" id="adresse" classSize="w-2/6"
                  :editing="adresseItem.editing" placeholder="Adresse"
                  @input="adresseItem.adresse = $event.target.value" @keyup-enter="saveChange(index)" />
            </div>
            <div class="flex wrap-items items-center">
               <InputTextField :value="adresseItem.code_postal" id="code_postal" classSize="w-2/6"
                  placeholder="Code postal" :editing="adresseItem.editing"
                  @input="adresseItem.code_postal = $event.target.value" @keyup-enter="saveChange(index)" />

               <InputTextField :value="adresseItem.ville" id="ville" classSize="w-2/6" :editing="adresseItem.editing"
                  placeholder="Ville" @input="adresseItem.ville = $event.target.value"
                  @keyup-enter="saveChange(index)" />

               <selectField :value="paysList[adresseItem.pays_id]" :options="paysList" :indexID="adresseItem.pays_id"
                  placeholder="Pays" id="pays_id" classSize="w-3/6" :editing="adresseItem.editing"
                  @input="adresseItem.pays_id = $event" @keyup-enter="saveChange(index)" />
            </div>
         </div>
      </div>
      <ErrorNotice v-if="isError" />

   </div>
</template>

<script>
import {  reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import UserService from "@/services/user.service";
import { mapState } from 'vuex';
export default {
   name: "Telephone",
   components: {
      TitleCat,
      InputTextField,
      ActionAddItem,
      selectField,
      ActionFields,
      ErrorNotice,
   },
   props: {
      adressesInfo: { required: true },
   },
   data() {
      return {
         adresses: reactive([]), // tableau où on stock toutes les adresses de maniere reactive (avec index)
         id: this.$route.params.id,
         adresseTypes: [],
         paysList: [],
         addItemEditing: false,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         newType_adresse_id: null,
         newAdresse: null,
         newCode_postal: null,   
         newVille: null,
         newPays_id: null,
         isError: false
      };
   },
   computed: {
      ...mapState('dataType', ['dataType']),
   },
   watch: {
      adressesInfo: {
         handler(adressesInfo) {
            if (adressesInfo) {
               this.adresses = adressesInfo.map((info) => {
                  return {
                     adresse: info.adresse,
                     code_postal: info.code_postal,
                     pays_id: info.pays_id,
                     type_adresse_id: info.type_adresse_id,
                     ville: info.ville,
                     editing: false,
                  };
               });
            }
         },
         deep: true,
      },
      dataType(newValue) {
         if (newValue) {
            this.loadAdressesTypes();
         }
      },
   },
   mounted() {
      this.loadAdressesTypes();
   },
   methods: {
      loadAdressesTypes() {
         if (this.dataType && this.dataType.pays && this.dataType.typeAdresses) {
            this.paysList = this.dataType.pays;
            this.adresseTypes = this.dataType.typeAdresses;
         }
      },
      saveChange(index) {
         this.updateAdresse(index);
      },
      saveDelete(index) {
         this.adresses[index].editing = false;
         this.deleteAdresse(index);
      },
      saveAdd() {
         this.postAdresse();
      },
      updateAdresse(index) {
         const datas = {
            individuId: this.$route.params.id,
            adresse: this.adresses[index].adresse,
            code_postal: parseInt(this.adresses[index].code_postal),
            pays_id: parseInt(this.adresses[index].pays_id),
            type_adresse_id: parseInt(this.adresses[index].type_adresse_id),
            ville: this.adresses[index].ville
         };
      
         UserService.updateItem(this.$route.params.id, datas, 'adresses').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.isError = false
               this.adresses[index].editing = false;
            },
            (error) => {
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      deleteAdresse(index) {
         UserService.deleteItem(this.$route.params.id, 'adresses', parseInt(this.adresses[index].type_adresse_id)).then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
         );
      },

      postAdresse() {
         const datas = {
            adresse: this.newAdresse,
            code_postal: parseInt(this.newCode_postal),
            pays_id: parseInt(this.newPays_id),
            type_adresse_id: parseInt(this.newType_adresse_id),
            ville: this.newVille
         };

         UserService.postItem(this.$route.params.id, datas, 'adresses').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.addItemEditing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      }, 
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
.wrap-list-adress .border-b:last-child{
   border-bottom: 0!important;
}
</style>
