<template>
    <div class="wrap mr-1 max-w-full" :class="editing ? classSize : ''">
        <div v-if="editing">
            <quill-editor v-model:value="internalValue" :options="editorOption" @ready="onEditorReady"
                @change="onEditorChange($event)" />
        </div>
        <p class="txt mb-1 pl-2 py-1 border border-white" v-show="!editing" v-html="value"></p>
    </div>
</template>

<script>
import { quillEditor } from 'vue3-quill';

export default {
    components: {
        quillEditor
    },
    props: {
        value: {
            required: true
        },
        classSize: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        editing: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            // Options de l'éditeur, y compris la personnalisation de la toolbar
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],  // Gras, Italique, Souligné, Barré
                        [{ 'list': 'bullet' }],                    // Liste à puces
                        [{ 'header': [1, 2, 3, false] }],          // En-têtes (Heading 1, 2, 3)
                        [{ 'color': [] }],                         // Couleur du texte
                        ['link'],                                  // Lien
                        [{ 'align': [] }],                         // Justification
                    ],
                    history: {
                        // Activer l'historique (undo/redo)
                        delay: 1000,
                        maxStack: 100,
                        userOnly: true
                    }
                }
            },
            internalValue: this.value,
            editorReady: false
        };
    },
    mounted() {
        this.internalValue = this.value;
    },
    methods: {
        onEditorReady() {
            this.editorReady = true;
        },
        onEditorChange() {
            this.$emit('input', this.internalValue);
        }
    },
};
</script>
