<template>
   <div id="civilite">
      <TitleCat :isAddedEntity="false">Profil</TitleCat>
      <div class="mb-1">

         <!-- Date de candidature -->
         <div class="flex wrap-items items-center">
            <Label>Date de Candidature</Label>

            <DateField :value="dateCandidature" id="dateCandidature" classSize="w-3/5" :editing="editingFields.profil"
               @input="dateCandidature = $event" />

            <ActionFields :editing="editingFields.profil" field="profil" @update:editing="editingFields.profil = $event"
               @is-error="isError = false" @save-change="saveChange" />
         </div>
         
         <!-- Situation de famille -->
         <div class="flex wrap-items items-center">
            <Label>Situation de Famille</Label>

            <InputTextField :value="situationFamille" id="situationFamille" classSize="w-full"
               :editing="editingFields.profil" @input="situationFamille = $event.target.value"
               @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Site internet -->
         <div class="flex wrap-items items-center">
            <Label>Site internet</Label>

            <InputTextField :value="siteInternet" id="siteInternet" classSize="w-full" :editing="editingFields.profil"
               @input="siteInternet = $event.target.value" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Domaine -->
         <div class="flex wrap-items items-center">
            <Label>Domaine</Label>

            <InputTextField :value="domaine" id="domaine" classSize="w-full" :editing="editingFields.profil"
               @input="domaine = $event.target.value" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- langues -->
         <div class="flex wrap-items items-center">
            <Label>Langues</Label>

            <InputTextField :value="langues" id="langues" classSize="w-full" :editing="editingFields.profil"
               @input="langues = $event.target.value" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Rémuneration -->
         <div class="flex wrap-items items-center">
            <Label>Rémuneration</Label>

            <InputTextField :value="remuneration" id="remuneration" classSize="w-full" :editing="editingFields.profil"
               @input="remuneration = $event.target.value" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Origine -->
         <div v-if="listeOrigine" class="flex wrap-items items-center">
            <Label>Origine</Label>

            <selectField :value="listeOrigine[typeOrigineId]" placeholder="Origine"  :options="listeOrigine"
               :indexID="typeOrigineId" id="origine_id" classSize="w-full" :editing="editingFields.profil"
               @input="typeOrigineId = $event" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Independant -->
         <div class="flex wrap-items items-center">
            <Label>Independant</Label>

            <CheckBoxField :value="independant" id="inde" classSize="" :editing="editingFields.profil"
               @input="independant = $event" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Date de Mise à Jour -->
         <div class="flex wrap-items items-center">
            <Label>Date de Mise à Jour</Label>

            <DateField :value="dateMiseAJour" id="dateMiseAJour" classSize="w-3/5" :editing="editingFields.profil"
               @input="dateMiseAJour = $event" />
         </div>

         <!-- Liste rouge -->
         <div class="flex wrap-items items-center">
            <Label>Liste rouge</Label>

            <CheckBoxField :value="listeRouge" id="listeRouge" classSize="" :editing="editingFields.profil"
               @input="listeRouge = $event" @keyup-enter="saveChange('profil')" />
         </div>

         <!-- Date de Création -->
         <div class="flex wrap-items items-center">
            <Label>Date de Création</Label>

            <DateField :value="dateCreation" id="dateCreation" classSize="w-3/5" :editing="editingFields.profil"
               @input="dateCreation = $event" />
         </div>

         <!-- Date de naissance -->
         <div class="flex wrap-items items-center">
            <Label>Date de naissance</Label>

            <div class="flex items-center">
               <DateField :value="dateNaissance" id="dateNaissance" classSize="w-3/5" :editing="editingFields.profil"
                  @input="dateNaissance = $event" />
               <p v-if="dateNaissance" class="ml-3 mb-1" style="min-width: 60px;">({{ calculerAge(dateNaissance) }} ans)</p>
            </div>
         </div>

         <!-- Date de disponibilité -->
         <div class="flex wrap-items items-center">
            <Label>Date de disponibilité</Label>

            <DateField :value="dateDispo" id="dateDispo" classSize="w-3/5" :editing="editingFields.profil"
               @input="dateDispo = $event" />
         </div>
      </div>
      <ErrorNotice v-if="isError" />
   </div>
</template>

<script>
import TitleCat from "@/components/profil/global/TitleCat.vue";
import Label from "@/components/profil/global/Label.vue";
import InputTextField from "@/components/profil/global/InputTextField.vue";
import DateField from "@/components/profil/global/DateField.vue";
import CheckBoxField from "@/components/profil/global/CheckBoxField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import UserService from "@/services/user.service";
import { mapState } from 'vuex';

export default {
   name: "ProfilInfo",
   components: {
      TitleCat,
      Label,
      InputTextField,
      DateField,
      CheckBoxField,
      selectField,
      ActionFields,
      ErrorNotice
   },
   props: {
      profilInfo: { required: true },
   },
   data() {
      return {
         id: this.$route.params.id,
         listeOrigine: null,
         typeOrigineId: null,
         domaine: null,
         dateDispo: null,
         independant: null,
         dateCandidature: null,
         dateMiseAJour: null,
         dateCreation: null,
         dateNaissance: null,
         siteInternet: null,
         situationFamille: null,
         listeRouge: null,
         langues: null,
         remuneration: null,
         editingFields: {
            profil: false,
         },
         isError: false
      };
   },
   computed: {
      ...mapState('dataType', ['dataType']),
   },
   watch: {
      profilInfo(profilInfo) {
         if (profilInfo) {
            this.domaine = profilInfo.domaine;
            this.independant = profilInfo.independant;
            this.dateDispo = profilInfo.dateDispo;
            this.dateCandidature = profilInfo.dateCandidature;
            this.dateMiseAJour = profilInfo.dateMiseAJour;
            this.dateCreation = profilInfo.dateCreation;
            this.dateNaissance = profilInfo.dateNaissance;
            this.siteInternet = profilInfo.siteInternet;
            this.situationFamille = profilInfo.situationFamille;
            this.listeRouge = profilInfo.listeRouge;
            this.typeOrigineId = profilInfo.typeOrigineId;
            this.langues = profilInfo.langues;
            this.remuneration = profilInfo.remuneration;
         }
      },
      dataType(newValue) {
         if (newValue) {
            this.loadOriginTypes(); // Recharge les types d'origine si dataType change
         }
      },
   },
   mounted() {
      this.loadOriginTypes();
   },
   methods: {
      loadOriginTypes() {
         if (this.dataType && this.dataType.typeOrigines) {
            this.listeOrigine = {}; // Réinitialise listeOrigine
            this.dataType.typeOrigines.forEach(item => {
               this.listeOrigine[item.type_origine_id] = item.type_origine;
            });
         }
      },
      saveChange(field) {
         this.updateIndividu(field)
      },
      updateIndividu(field) {
         const data = {};

         switch (field) {
            case 'profil':
               data.domaine = this.domaine ? this.domaine : '';
               data.independant = this.independant;
               data.dateDispo = this.dateDispo;
               data.dateCandidature = this.dateCandidature;
               data.dateMiseAJour = this.dateMiseAJour;
               data.dateCreation = this.dateCreation;
               data.dateNaissance = this.dateNaissance;
               data.siteInternet = this.siteInternet ? this.siteInternet : '';
               data.situationFamille = this.situationFamille ? this.situationFamille : '';
               data.listeRouge = this.listeRouge;
               data.typeOrigineId = parseInt(this.typeOrigineId);
               data.langues = this.langues ? this.langues : '';
               data.remuneration = this.remuneration ? this.remuneration : '';

               break;
            default:
               break;
         }
         UserService.putIndividus(this.$route.params.id, data).then(
            (response) => {
               console.log('response', response);
               this.editingFields[field] = false;
               this.isError = false
            },
            (error) => {
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
     
      formatDate(dateString) {
         const date = new Date(dateString);
         const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
         return date.toLocaleDateString('fr-FR', options);
      },
      calculerAge(dateNaissance) {
         const birthDate = new Date(dateNaissance);
         const today = new Date();
         const age = today.getFullYear() - birthDate.getFullYear();

         const isBirthdayPassed = today.getMonth() > birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

         return isBirthdayPassed ? age : age - 1;
      }
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
