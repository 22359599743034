<template>
   <div id="contact" class="">
      <TitleCat :isAddedEntity="true" @update:editing="addItemEditing = $event">Contact(s)</TitleCat>

      <!-- Ajout d'une nouvelle adresse -->
      <div class="add">
         <div v-if="addItemEditing" class="flex wrap-items items-center p-2 border-b border-gray-300 bg-slate-50 mb-2">

            <DateField :value="newDate_contact" id="date_contact" classSize="w-2/6" :editing="true"
               @input="newDate_contact = $event" />

            <selectField :value="newType_contact_id" :options="contactTypes" indexID="" id="type_adresse_id"
               classSize="w-2/6" placeholder="Type de Contact" :editing="true"
               @change="newType_contact_id = $event.target.value" @keyup-enter="saveAdd()" />

            <selectField :value="newInterlocuteur_id" :options="interlocuteur" indexID="" id="type_adresse_id"
               classSize="w-2/6" placeholder="Interlocuteur" :editing="true"
               @change="newInterlocuteur_id = $event.target.value" @keyup-enter="saveAdd()" />


            <ActionAddItem field="contact" :editing="addItemEditing" @update:editing="addItemEditing = $event"
               @is-error="isError = false" @save-add="saveAdd()">
            </ActionAddItem>
         </div>

         <TextareaField v-if="addItemEditing" :value="newCommentaire_contact" id="commentaire_contact"
            classSize="w-full" :editing="true" placeholder="Commentaire" @input="newCommentaire_contact = $event" />
      </div>


      <div class="" :key="keyReload">
         <div v-for="(contactItem, index) in contacts" :key="index" class="border-b border-gray-300 mb-6 pb-2">
            <div class="flex wrap-items items-center mb-1 border-b border-gray-200">
               <DateField :value="contactItem.date_contact" id="date_contact" classSize="w-2/6" :editing="false"
                  @input="contactItem.date_contact = $event" /> :

               <selectField :value="contactTypes[contactItem.type_contact_id]" :options="contactTypes"
                  :indexID="contactItem.type_contact_id" id="type_contact_id" classSize="w-4/6"
                  placeholder="Type de Contact" :editing="false" @input="contactItem.type_contact_id = $event"
                  @keyup-enter="saveChange(index)" />

               <selectField :value="interlocuteur[contactItem.interlocuteur_id]" :options="interlocuteur"
                  :indexID="contactItem.interlocuteur_id" id="interlocuteur_id" classSize="w-3/6"
                  placeholder="Interlocuteur" :editing="contactItem.editing"
                  @input="contactItem.interlocuteur_id = $event" @keyup-enter="saveChange(index)" />

               <ActionFields :editing="contactItem.editing" :index="index" field="adresse" :deletable="true"
                  @is-error="isError = false" @update:editing="contactItem.editing = $event"
                  @save-delete="saveDelete(index)" @save-change="saveChange(index)"></ActionFields>
            </div>

            <TextareaField :value="contactItem.commentaire_contact" id="commentaire_contact" classSize="w-full"
               :editing="contactItem.editing" placeholder="Commentaire"
               @input="contactItem.commentaire_contact = $event" />

         </div>
      </div>
      <ErrorNotice v-if="isError" />

   </div>
</template>

<script>
import {  reactive } from "vue";
import TitleCat from "@/components/profil/global/TitleCat.vue";
import DateField from "@/components/profil/global/DateField.vue";
import selectField from "@/components/profil/global/selectField.vue";
import TextareaField from "@/components/profil/global/TextareaField.vue";
import ActionFields from "@/components/profil/global/ActionFields.vue";
import ActionAddItem from "@/components/profil/global/ActionAddItem.vue";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import UserService from "@/services/user.service";
import { mapState } from 'vuex';

export default {
   name: "Telephone",
   components: {
      TitleCat,
      ActionAddItem,
      DateField,
      selectField,
      TextareaField,
      ActionFields,
      ErrorNotice
   },
   props: {
      contactInfo: { required: true },
   },
   data() {
      return {
         contacts: reactive([]), // tableau où on stock toutes les contacts de maniere reactive (avec index)
         id: this.$route.params.id,
         contactTypes: [],
         interlocuteur: {
            0: "(non defini)",
            867: "Alain MATHIEU",
            1334: "Laurent MAUPU",
            1345: "Caroline de BOTTON",
            5696: "Pierre de CHAULIAC",
            48507: "Octave LAURENTIN",
            50109: "Antoine CONDIZI",
            50141: "Maya ALESHKEVICH",
            51901: "Laure de MURAT",
         },
         addItemEditing: false,
         keyReload: 0, // variable pour update le component et mettre à jour les données apres les differentes operation
         newDate_contact: null,
         newType_contact_id: null,
         newInterlocuteur_id: null,   
         newCommentaire_contact: null,
         isError: false
      };
   },
   computed: {
      ...mapState('dataType', ['dataType']),
   },
   watch: {
      contactInfo: {
         handler(contactInfo) {
            if (contactInfo) {
               contactInfo.sort((a, b) => new Date(b.date_contact) - new Date(a.date_contact));
               this.contacts = contactInfo.map((info) => {
                  return {
                     commentaire_contact: info.commentaire_contact,
                     date_contact: info.date_contact,
                     interlocuteur_id: info.interlocuteur_id,
                     type_contact_id: info.type_contact_id,
                     editing: false,
                  };
               });
            }
         },
         deep: true,
      },
      dataType(newValue) {
         if (newValue) {
            this.loadContactTypes(); // Recharge les types de contact si dataType change
         }
      },
   },
   mounted() {
      this.loadContactTypes();
   },
   methods: {
      loadContactTypes() {
         if (this.dataType && this.dataType.typeContacts) {
            this.contactTypes = {}; // Réinitialise contactTypes
            this.dataType.typeContacts.forEach(item => {
               this.contactTypes[item.type_contact_id] = item.type_contact;
            });
         }
      },
      saveChange(index) {
         this.updateContact(index);
      },
      saveDelete(index) {
         this.contacts[index].editing = false;
         this.deleteContact(index);
      },
      saveAdd() {
         this.postContact();
      },

      updateContact(index) {
         const datas = {
            date_contact: this.contacts[index].date_contact,
            commentaire_contact: this.contacts[index].commentaire_contact,
            interlocuteur_id: parseInt(this.contacts[index].interlocuteur_id),
            type_contact_id: parseInt(this.contacts[index].type_contact_id),
         };
      
         UserService.updateItem(this.$route.params.id, datas, 'contacts').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.isError = false
               this.contacts[index].editing = false;
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },
      deleteContact(index) {
         UserService.deleteItem(this.$route.params.id, 'contacts', parseInt(this.contacts[index].type_contact_id), this.contacts[index].date_contact).then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      },

      postContact() {
         const datas = {
            date_contact: this.newDate_contact,
            commentaire_contact: this.newCommentaire_contact,
            interlocuteur_id: parseInt(this.newInterlocuteur_id),
            type_contact_id: parseInt(this.newType_contact_id),
         };
         
         UserService.postItem(this.$route.params.id, datas, 'contacts').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.keyReload++
               this.addItemEditing = false;
               this.isError = false
            },
            (error) => {
               this.isError = true;
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
      }, 
   },
};
</script>
<style lang="scss" scoped>
.wrap {
   input {
      max-width: 100%;
   }
}
</style>
